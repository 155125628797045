<template>
    <div>

        <el-container>
            <el-header>
                <div class="header_box" style="background-color:#f1f1f1">
                    <el-row type="flex" justify="space-between" :span="23">
                        <el-col>
                            <h1 style="font-size:40px;line-height:20px">后台管理系统</h1>

a                        </el-col>
                        <el-col :span="1">

                            <!--头像下拉菜单-->
                            <el-dropdown class="user" placement="bottom-start" trigger="click" @command="handleCommand">
                                <el-button type="" style="border:0px; background-color:#ffffff00">
                                    <!--管理员、操作员头像-->
                                    <img src="../assets/admin.png" style="width:40px;height:40px;" v-if="role" />
                                    <img src="../assets/operator.png" style="width:40px;height:40px;" v-else />
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="logout">注销</el-dropdown-item>
                                    <el-dropdown-item command="changePassword">修改密码</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>

                        </el-col>            
                    </el-row>
                </div>
            </el-header>
            <el-main>

                <!--标签-->
                <el-tabs tab-position='left' style=" height:80vh ;" type="border-card" weight="100px">
                    <el-tab-pane label="密狗记账">
                        <h1>密狗记账</h1>
                        <AccountingCom></AccountingCom>
                    </el-tab-pane>
                    <el-tab-pane label="监管日志">
                        <h1>监管日志</h1>
                        <LogCom></LogCom>
                    </el-tab-pane>
                    <el-tab-pane label="角色管理" :disabled="!role">
                        <h1>角色管理</h1>
                        <RoleCom></RoleCom>
                    </el-tab-pane>
                    <el-tab-pane label="远程控制">
                        <h1>远程控制</h1>
                        <Contror></Contror>
                    </el-tab-pane>
                </el-tabs>
            </el-main>

            <!--抽屉，修改个人密码组件-->
            <el-drawer :visible.sync="drawer" :with-header="false">
                <img src="../assets/admin.png" alt="logo"  v-if="this.role">
                <img src="../assets/operator.png" alt="logo" v-else>
                <el-form label-position="top" :model="form" :rules="rules" ref="form">
                    <el-form-item label="用户名" prop="name">
                        <div style="width:500px;  margin:auto ">
                            <el-input v-model="form.name" disabled="true"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="原密码" prop="lastPassword">
                        <div style="width:500px;  margin:auto ">
                            <el-input placeholder="输入原密码" v-model="form.lastPassword" show-password></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="新密码" prop="newPassword">
                        <div style="width:500px; margin:auto ">
                            <el-input placeholder="输入新密码" v-model="form.newPassword" show-password></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="新密码" prop="newPassword">
                        <div style="width:500px; margin:auto ">
                            <el-input placeholder="确定新密码" v-model="newPasswordAgain" show-password></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button type='primary' @click="submitForm('form')">确认密码</el-button>
                    </el-form-item>
                </el-form>
            </el-drawer>
        </el-container>
    </div>
</template>
<script>
import AccountingCom from '../components/Accounting.vue'//记账组件
import LogCom from '../components/RegulatoryLog.vue'//日志组件
import RoleCom from '../components/Role.vue'//角色管理组件
import Contror from '../components/Contror.vue'//远程控制组件
import axios from 'axios'


export default {
    components: {
        AccountingCom,
        LogCom,
        RoleCom,
        Contror
    },
    created() {
        //响应拦截器
        axios.interceptors.response.use(
            response => {
                const newToken = response.headers['authorization'];
                if (newToken) {
                    // 将新Token保存到本地存储中
                    console.log("获取了新token:")
                    console.log(newToken)
                    sessionStorage.setItem('token', newToken);
                }
                return response;
            },
            error => {
                if (error.response && error.response.status === 401) {
                    
                    sessionStorage.clear()
                    if (this.$route.path != '/') {
                        this.$router.push('/')
                    }
                    // Token无效或已过期，跳转到登录页面重新获取Token
                }
                return Promise.reject(error);
            }
        );

    },
    data() {
        return {
            //用户权限
            role: 'true' === sessionStorage.getItem('role'),
            //抽屉开关
            drawer: false,
            //用户名密码
            form: {
                name: sessionStorage.getItem('name'),
                lastPassword: '',
                newPassword: '',
            },
            //确认新密码
            newPasswordAgain: '',

            rules: {
                lastPassword: [
                    { required: true, message: '请输入原密码', trigger: 'blur' },

                ],
                newPassword: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    { min: 6, max: 30, message: '长度在 6 到 30 个字符', trigger: 'blur' }
                ],

            },


            //Api
            patchuserApi: 'http://47.92.23.191:8005/dongle/patchmyuser'
        }
    },
    rule: {

    },
    methods: {
        //打开侧边抽屉
        openDrawer() {
            this.drawer = true
        },


        submitForm(formName) {
            if(this.form.newPassword != this.newPasswordAgain){
                alert('两次输入密码不同')
                return 
            }

            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios.patch(this.patchuserApi, {
                        'name': this.form.name,
                        'oldpassword': this.form.lastPassword,
                        'newpassword': this.form.newPassword
                    }, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': sessionStorage.getItem('token')
                        },
                    }
                    )
                        .then((res) => {
                            //发送修改成功/失败
                            let msg = res.data
                            this.$message(msg)
                            // console.log('修改个人密码成功:', res)
                            this.form.lastPassword = ''
                            this.form.newPassword = ''
                            this.newPasswordAgain = ''
                        })
                        .catch((err) => {
                            this.$message.error('修改密码出错了')
                            console.log('修改密码出错:', err)
                        })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        //头像下拉菜单处理
        handleCommand(command) {
            //注销和修改密码
            if (command === 'logout') {
                sessionStorage.clear()
                this.$router.push('/')
            } else if (command === 'changePassword') {
                this.openDrawer();
            }
        }
    },

    //跳转home前验证登录状态
    beforeRouteEnter: (to, from, next) => {
        next(() => {
            if (sessionStorage.getItem("token")) {
                next('/home')
            } else (
                next('/')
            )

        })
    }
}
</script>

<style>
.header_box .user {
    padding: 10px;
    background-color: #ffffff00;
}

.el-tabs__item {
    height: 60px !important;
    line-height: 60px !important;
    font-size: 20px !important;
}
</style>