import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import VueCookies from 'vue-cookies'
import store from './store.js'

Vue.use(VueCookies)
Vue.use(ElementUI)



Vue.config.productionTip = false



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
/**
 * // 创建一个axios实例
const api = axios.create({
  baseURL: '/api',
});

// 请求拦截器
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      // 将Token添加到请求头中
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
api.interceptors.response.use(
  response => {
    const newToken = response.headers['authorization'];
    if (newToken) {
      // 将新Token保存到本地存储中
      localStorage.setItem('token', newToken);
    }
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      // Token无效或已过期，跳转到登录页面重新获取Token
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;

 */