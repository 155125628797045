<template >
  <!--日志组件-->
  <div>
    <!--表格-->
    <el-table :data="tableData" style="width: 100%;">
      <el-table-column label="加密狗id" prop="id_nt158" width="140" align="center">
      </el-table-column>
      <el-table-column label="登录时间" prop="time_start" width="160" align="center">
      </el-table-column>
      <el-table-column label="登出时间" prop="time_last" width="160" align="center">
      </el-table-column>
      <el-table-column label="wan口ip地址" prop="remote_ip" width="140"  align="center">
      </el-table-column>
      <el-table-column label="本地ip" prop="local_ip" width="140" align="center">
      </el-table-column>
      <el-table-column label="省份" prop="province" width="80" align="center">
      </el-table-column>
      <el-table-column label="城市" prop="city" width="80" align="center">
      </el-table-column>
      <el-table-column label="区县" prop="region" width="80" align="center">
      </el-table-column>
      <el-table-column label="邮管ID" prop="id_post" width="120" align="center">
      </el-table-column>
      <el-table-column width="100" property="state" label="软件状态" align="center">
      </el-table-column>
      <el-table-column align="right">
        <!--搜索栏-->
        <template v-slot:header>
          <el-input v-model="keywords" size="mini" placeholder="搜索id" />
        </template>

        <!--更多数据-->
        <template v-slot:default="{ $index }">
          <!--弹出表格-->
          <el-popover placement="right" width="800" trigger="hover" @show="getMore($index)" >
            <el-table :data="moreData">
              <el-table-column label="地址" prop="addr" width="340"></el-table-column>
              <el-table-column width="100" property="machinetype" label="设备型号"></el-table-column>
              <el-table-column width="100" property="machineindex" label="设备序号"></el-table-column>
              <el-table-column width="100" property="raytype" label="射线源类型"></el-table-column>
              <el-table-column width="100" property="voltage" label="射线源电压"></el-table-column>
              <el-table-column width="100" property="current" label="射线源电流"></el-table-column>
              <el-table-column width="100" property="scannertype" label="探测器类型"></el-table-column>
              
            </el-table>
            <!--获取当前行的更多信息并弹出表格-->
            <el-button slot="reference" size="mini" type="primary" @show="getMore($index)">查看更多信息</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>

    <!--分页组件-->
    <div>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
        :page-size="pagesize" :pager-count="10" layout=" prev, pager, next" :total="totalElements">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data() {
    return {
      keywords: '', // 搜索的关键字 
      pagesize: 10,// 一页显示的数量
      currentPage: 1,   //请求的页面
      tableData: [],    //日志数据
      moreData: [],    //日志更多数据
      totalElements: 0,   //总数量
    }
  },
  methods: {

    //日志切页
    getLog() {
      //判断是否有进行搜索操作
      if (this.keywords === '') {
        axios.get('http://47.92.23.191:8005/dongle/clientlog/list', {
          // 传递的参数
          responseType: 'json',
          headers: {

            Authorization: sessionStorage.getItem('token')
          },
          params: {
            page: this.currentPage - 1,
            size: this.pagesize,
          }
        })
          .then((res) => {
            this.tableData = res.data.content
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        axios.get('http://47.92.23.191:8005/dongle/clientlog/' + this.keywords, {
          responseType: 'json',
          headers:{
            Authorization: sessionStorage.getItem('token')
          },
          params: {
            page: this.currentPage - 1,
            size: this.pagesize
          }
        })
          .then((res) => {
            this.tableData = res.data.content
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },

    //获取日志更多内容
    getMore(index) {
      let a = []
      //用下标获取对应的更多数据
      a.push(this.tableData[index])
      this.moreData = a
    },

    //搜索函数
    searchResource() {
      //判断搜索栏
      if (this.keywords != '') {
        axios.get('http://47.92.23.191:8005/dongle/clientlog/' + this.keywords, {
          responseType: 'json',
          headers: {
            Authorization: sessionStorage.getItem('token')
          },
          params: {
            page: this.currentPage - 1,
            size: this.pagesize,
          },
        })
          .then((res) => {
            this.tableData = res.data.content
            this.totalElements = res.data.totalElements
          })
          .catch((err) => {
            console.log('搜索出错了')
            console.log(err)
          })
      } else {
        this.currentPage = 1;
        axios.get('http://47.92.23.191:8005/dongle/clientlog/list', {
          responseType: 'json',
          headers: {
            
            Authorization: sessionStorage.getItem('token')
          },
          params: {
            page: 0,
            size: this.pagesize,
          }
        })
          .then((res) => {
            this.tableData = res.data.content
            this.totalElements = res.data.totalElements
          })
          .catch((err) => {
            console.log('获取日志失败')
            console.log(err)
          })
      }
    },
    handleSizeChange(psize) {
      this.pagesize = psize;
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getLog()
    }
  },

  beforeMount() {
    //获取日志
    axios.get('http://47.92.23.191:8005/dongle/clientlog/list', {
      // 传递的参数
      responseType: 'json',
      headers: {

        Authorization: sessionStorage.getItem('token')
      },
      params: {
        page: 0,
        size: this.pagesize,
      }
    })
      .then((res) => {
        this.tableData = res.data.content
        this.totalElements = res.data.totalElements
      })
      .catch((err) => {
        console.log('获取日志失败')
        console.log(err)
      })


  },
  watch: { //监听搜索框内容，当搜索框内容发生变化时调用searchResource方法
    keywords: {
      handler() {
        this.searchResource()
      }
    }
  }
}

</script>
