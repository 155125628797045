<template>
  <div id="app">
    <div style="height:20px"></div>
    <router-view />
  </div>
</template>

<script>
export default {

  data() {
    return {
      input: ''
    }
  },
  methods: {
  }
}
</script>

<style>
#app {
  height: 100vh;
  width: 98vw;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  background-color: #409EFF;
}
</style>
