<template>
  <!--角色管理-->
  <div>
    <div>
      <el-table height="50vh" :data="filtList" tooltip-effect="dark" style="width: 100%">
        <el-table-column label="角色" width="500">
          <template v-slot:default>操作员</template>
        </el-table-column>
        <el-table-column label="用户名" width="300" prop="name">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column width="400" align="right">
          <template v-slot:header>
            <el-input v-model="keyWords" size="mini" placeholder="搜索用户名" />
          </template>
          <template v-slot:default="scope">
            <el-input placeholder="输入新密码" v-model="scope.row.password" />
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="432">
          <template slot-scope="scope">
            <el-button @click="delUser(scope.row.name)">删除用户</el-button>
            <el-button @click="changePassword(scope.row)">修改密码</el-button>
          </template>

        </el-table-column>
      </el-table>
    </div>
    <div style="margin-top: 20px">
      <el-form label-position="top">
        <el-form-item>
          <el-input v-model="newName" placeholder="用户名"></el-input>
        </el-form-item>

        <el-form-item>
          <el-input v-model="newPassword" placeholder="密码"></el-input>
        </el-form-item>
        <el-form-item label-position="top">
          <el-button type="primary" @click="postUser" v-loading.fullscreen.lock="isLoading">添加操作员</el-button>
        </el-form-item>
      </el-form>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'RoleCom',
  data() {
    return {
      isLoading: false,//加载状态
      userList: [],//所有用户
      filtList: [],//搜索过滤的用户
      keyWords: '',//搜索关键词

      //添加新操作员表单
      newName: '',
      newPassword: '',

      //api
      changeApi: 'http://47.92.23.191:8005/dongle/patch',//修改操作员密码
      getUserApi: 'http://47.92.23.191:8005/dongle/getuser',//获取操作员列表
      postUserApi: 'http://47.92.23.191:8005/dongle/postuser',//新增操作员
      delUserApi: 'http://47.92.23.191:8005/dongle/deleteuser',//删除操作员

    }     
  },

  mounted() {
    this.getList()
  },
  watch: {
    keyWords: {
      handler() {
        this.search()
      }

    }
  },
  methods: {
    //修改用户密码
    changePassword(row) {
      let changeList = {
        name: row.name,
        password: row.password
      }
      axios.patch(this.changeApi, changeList, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': sessionStorage.getItem('token')
        }
      })
        .then((res) => {
          this.$message({
            type:'success',
            message:'修改密码成功'
          })
          row.password = ''
          return res
        })
        .catch((err) => {
          alert('修改密码失败')
          console.log(err)
        })
    },

    //切换加载状态
    ChangeLoad() {
      this.isLoading = !this.isLoading
    },

    search() {
      let input = this.keyWords.toUpperCase()
      if (input === '') {
        this.filtList = this.userList
      } else {
        let list = []
        for (let i = 0; i < this.userList.length; i++) {
          if (input == this.userList[i].name.toUpperCase()) {
            list.push(this.userList[i])
          }
        }
        this.filtList = list
      }
    },

    //获取操作员列表
    getList() {
      axios.get(this.getUserApi, {
        headers: {
          Authorization: sessionStorage.getItem('token')
        },
      })
        .then((res) => {
          this.userList = res.data
          this.filtList = this.userList
          this.userList.forEach((item) => {
            item.password = ''
          });
        })
        .catch((err) => {
          console.log('获取操作员出错')
          console.log(err)
        })
    },

    postUser() {
      //加载动画
      this.ChangeLoad()
      setTimeout(this.ChangeLoad, 500)

      let newUser = {
        'name': this.newName,
        'password': this.newPassword
      }
      axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      axios.post(this.postUserApi, newUser, {
        headers: {

          Authorization: sessionStorage.getItem('token')
        },
      })
        .then((res) => {
          this.newName = ''
          this.newPassword = ''
          this.$message.info(res.data)
          this.getList()
        })
        .catch((err) => {
          console.log('添加新操作员失败')
          console.log(err)
        })
    },

    //删除操作员
    delUser(userName) {
      //发送请求删除
      axios.delete(this.delUserApi + '?name=' + userName, {
        headers: {

          Authorization: sessionStorage.getItem('token')
        },
      })
        .then((res) => {
          this.getList()
          return res
        })
        .catch((err) => {
          console.log('删除用户出错')
          console.log('删除用户', userName, '出错')
          console.log('err=', err)
        })
      //重新拉取操作员
    },
  }
}
</script>
