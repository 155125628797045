<template>
  <!--密狗记账-->
  <el-form ref="form" :model="formData" @submit.native.prevent="onSubmit">
    <el-table :data="formData" style="width: 100%">
      <el-table-column label="加密狗id" align="center" width="180">
        <template slot-scope="scope">
          <el-input v-model="scope.row.id_nt158" placeholder="输入id"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="用户企业" align="center" width="280">
        <template slot-scope="scope">
          <el-input v-model="scope.row.user_company" placeholder="请输入公司名称"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="企业地址" align="center" width="350">
        <template slot-scope="scope">
          <el-input v-model="scope.row.user_addr" placeholder="请输入地址"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="联系人" align="center" width="150">
        <template slot-scope="scope">
          <el-input v-model="scope.row.user_contact" placeholder="联系人姓名"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="联系电话" align="center" width="150">
        <template slot-scope="scope">
          <el-input v-model="scope.row.user_tel" placeholder="请输入电话"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="安装时间" align="center" width="250">
        <template slot-scope="scope">
          <el-date-picker v-model="scope.row.time_start" type="datetime" placeholder="选择日期时间">
          </el-date-picker>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button @click="delRow(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-button type="primary" @click="addRow">添加新行</el-button>
    <el-button type="primary" @click="onSubmit">提交</el-button>
  </el-form>
</template>
<script>
import axios from 'axios'
export default {

  name: "AccountingFCom",
  data() {
    return {
      formData: [{
        id_nt158: '',
        user_company: '',
        user_addr: '',
        user_contact: '',
        user_tel: '',
        time_start: '',
      }],
      rowNum: 8,//可添加行数，最多可添加8行，即最多同时提交9行，默认显示1行

      //Api
      submitApi: 'http://47.92.23.191:8005/dongle/dongle/addctrlclient',//提交表单
    }
  },
  methods: {

    //添加新行
    addRow() {
      if (this.rowNum != 0) {
        //可添加行数减少
        this.rowNum--
        this.formData.push({
          "id_nt158": '',
          "user_company": '',
          "user_addr": '',
          "user_contact": '',
          "user_tel": '',
          "time_start": '',
        })
      } else {
        this.$message({
          message: '已达最大添加行数',
          type: 'warning'
        });
      }

    },
    //删除行
    delRow(index) {
      //可用行数增加
      this.rowNum++
      this.formData.splice(index, 1);
    },

    //检查表格
    checkForm() {
      for (let i = 0; i < this.formData.length; i++) {
        const row = this.formData[i];
        if (
          row.id_nt158.trim === '' ||
          row.user_company === '' ||
          row.user_addr === '' ||
          row.user_contact === '' ||
          row.user_tel === '' ||
          row.time_start === ''
        ) {
          this.$message.error('请填写完整表格！');
          return false;
        }
      }
      return true;
    },

    //提交表格
    onSubmit() {
      if (!this.checkForm()) {
        return
      }

      axios.post(this.submitApi, this.formData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('token'),
        }
      })
        .then((res) => {
          this.$message({
            message: '提交成功',
            type: 'success'
          });
          return(res)
        })
        .catch((err) => {
          this.$message.error("提交失败")
          if (err.response.status === 500) {
            //延时提示，防止遮挡
            setTimeout(() => { this.$message.error('请检查表格内是否有某条数据的id已在数据库中存在') }, 500)
          }
        })
    }
  }
}
</script>