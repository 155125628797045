import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Home from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    name:'Login',
    component:Login
  },
  {
    path:'/Home',
    name:'Home',
    component:Home
  }
]

const router = new VueRouter({
  routes
})

export default router

