import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    count:0,
    isRoot:false,//是否是管理员
    isLogin: true,//是否登录
    token: '1'
  },
  mutations: {
    increment (state) {
      state.count++
    }
  }
})

export default store