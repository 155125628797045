<template>
  <div id="Login-box">
    <el-card class="box-card " style="min-height: 200px;" align="middle">
      <div class="mid">
        <p style="margin-left: 0;color: #505458; font-size: 40px">账 号 登 录</p>
      </div>
      <el-form label-position="top">
        <el-form-item>
          <el-input prefix-icon="el-icon-user" placeholder="输入账号" v-model="name"></el-input>
        </el-form-item>

        <el-form-item>
          <el-input prefix-icon="el-icon-key" placeholder="输入密码" v-model="password" show-password></el-input>
        </el-form-item>
      </el-form>
      <el-form>
        <el-form-item label-position="top">
          <el-button type="primary" @click='tryLogin()' v-loading.fullscreen.lock="isLoading">登录</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script lang="ts">
import axios from 'axios'
export default {
  name: 'LoginCom',
  data() {
    return {
      name: '',
      password: '',
      isLoading: false
    }
  },
  methods: {
    //改变加载状态
    ChangeLoad: function () {
      this.isLoading = !this.isLoading
    },

    //验证账号密码
    tryLogin: function () {

      //加载动画
      this.ChangeLoad()
      setTimeout(this.ChangeLoad, 2000)

      //发送请求验证密码
      let form = { 'name': this.name, 'password': this.password }
      axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      axios.post('http://47.92.23.191:8005/dongle/login', form)
        .then((res) => {
          //存入用户名和token和用户权限
          sessionStorage.setItem('name', this.name)
          sessionStorage.setItem('token', res.data.token)
          sessionStorage.setItem('role', res.data.role)
          // console.log(res.data.token)
          //发送成功消息
          this.$message({
            message: '登录成功',
            type: 'success'
          });
          //跳转home目录
          this.$router.push('/home')
        })
        .catch((err) => {
          console.log(err)
          if (err.response.status === 401) {
            this.$message.error('请检查账号密码')
          } else {
            this.$message.error('err' + err)
          }
        })
    }
  }
}
</script>

<style>
.el-card {
  min-width: 380px;
  margin: 180px auto;
  width: 100px;
  transition: all .5s;
}

.el-card:hover {
  margin-top: 175px;
}

.mid {
  margin-top: 25%;
  height: 50%;
}
</style>