<template>
    <!--远程控制-->
    <div>

        <!--表格-->
        <el-table :data="tableData" style="width: 100%">
            <el-table-column label="加密狗id" width="180" prop="id_nt158">
            </el-table-column>
            <el-table-column label="用户企业" width="180" prop="user_company">
            </el-table-column>
            <el-table-column label="企业地址" prop="user_addr">
            </el-table-column>
            <el-table-column label="联系人" prop="user_contact">
            </el-table-column>
            <el-table-column label="联系电话" prop="user_tel">
            </el-table-column>
            <el-table-column label="安装时间" prop="time_start">
            </el-table-column>
            <!--远程指令和参数-->
            <el-table-column label="远程控制指令">
                <template slot-scope="scope">
                    <el-select v-model="scope.row.command" @change="resetParam(scope.row)" :disabled="!role"
                        placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="参数">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.param" placeholder="指令参数"
                        :disabled="!(scope.row.command == 'param' && role)"></el-input>
                </template>
            </el-table-column>

            <el-table-column align="center">
                <!--搜索栏-->
                <template v-slot:header>
                    <el-input placeholder="id搜索" v-model="keyWord"></el-input>
                </template>
                <!--发送指令和参数-->
                <template v-slot:default="scope">
                    <el-button slot="reference" size="mini" type="primary" :disabled="!role"
                        @click="sendCommand(scope.row.id_nt158, { command: scope.row.command, param: scope.row.param })">发送命令</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!--分页组件-->
        <div>
            <el-pagination @current-change="handleCurrentChange" background :page-size="pageSize" :pager-count="10"
                layout=" prev, pager, next" :total="totalElements">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'ControrCom',
    mounted() {
        //挂载时获取表格
        axios.get('http://47.92.23.191:8005/dongle/dongle/list', {

            headers: {
                Authorization: sessionStorage.getItem('token')
            },
            params: {
                'page': '0',
                'size': 8,
            }
        })
            .then((res) => {
                this.tableData = res.data.content
                this.totalElements = res.data.totalElements
            })
            .catch((err) => {
                console.log('获取控制列表出错：')
                console.log(err)
            })
    },
    data() {
        return {
            currentPage: 1,//请求的页面
            totalElements: 1,//总条数
            tableData: [],//表格内容
            pageSize: 8,//页面显示条数
            keyWord: '',//搜索关键词
            role: 'true' == sessionStorage.getItem('role'),
            options: [{//命令列表
                value: 'update',
                label: 'update'
            }, {
                value: 'invalid',
                label: 'invalid'
            }, {
                value: 'param',
                label: 'param'
            }],

            //Api
            getListApi: 'http://47.92.23.191:8005/dongle/dongle/list',//获取控制列表
            sendCommandApi: 'http://47.92.23.191:8005/dongle/dongle/',//发送指令
            searchApi: 'http://47.92.23.191:8005/dongle/dongle/',//搜索
        }
    },
    methods: {
        //命令切换时清空param参数
        resetParam(row) {
            if (row.command != 'param') {
                row.param = '';
            }
        },
        //发送指令
        sendCommand(id, contror) {
            axios.put(this.sendCommandApi + id, {
                command: contror.command,
                param: contror.param
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: sessionStorage.getItem('token')
                },
            })
                .then((res) => {
                    this.$message({
                        type:'success',
                        message:'提交成功'
                    })
                    return res
                })
                .catch((err) => {
                    alert('提交失败')
                    console.log(err)
                })
        },

        //切页时触发
        handleCurrentChange(currentPage) {
            //将请求页面设置为当前页面
            this.currentPage = currentPage
            this.getList()
        },

        //获取新页内容
        getList() {
            //如果搜索框为空
            if (this.keyWord === '') {
                axios.get(this.getListApi, {
                    responseType: 'json',
                    headers: {

                        Authorization: sessionStorage.getItem('token')
                    },
                    params: {
                        page: this.currentPage - 1,
                        size: 8,
                    }
                })
                    .then((res => {
                        this.tableData = res.data.content
                    }))
                    .catch((err) => {
                        console.log('切页失败,err:', err)
                    })
            } else {
                //不为空
                axios.get(this.searchApi + this.keyWord, {
                    responseType: 'json',
                    headers: {

                        Authorization: sessionStorage.getItem('token')
                    },
                    params: {
                        page: this.currentPage - 1,
                        size: 8
                    }
                })
                    .then((res) => {
                        this.tableData = res.data.content
                    })
                    .catch((err) => {
                        console.log('搜索切页失败,err=', err)
                    })
            }
        },

        //搜索栏变化时触发
        search() {
            //搜索栏变化时，将请求页面设为1
            this.currentPage = 1

            //发生了清空搜索栏操作
            if (this.keyWord === '') {
                axios.get(this.getListApi, {
                    responseType: 'json',
                    headers: {
                        Authorization: sessionStorage.getItem('token')
                    },
                    params: {
                        page: 0,
                        size: 8,
                    }
                })
                    .then((res => {
                        //更新表格内容
                        this.tableData = res.data.content
                        //更新总数
                        this.totalElements = res.data.totalElements
                    }))
                    .catch((err) => {
                        console.log('搜索失败,err:', err)
                    })
            } else {
                axios.get(this.searchApi + this.keyWord, {
                    responseType: 'json',
                    headers: {

                        Authorization: sessionStorage.getItem('token')
                    },
                    params: {
                        page: 0,
                        size: 8
                    }
                })
                    .then((res) => {
                        this.tableData = res.data.content
                        this.totalElements = res.data.totalElements
                    })
                    .catch((err) => {
                        console.log('搜索失败,err=', err)
                    })
            }
        }
    },
    watch: {
        //监视搜索栏，发生变化时调用搜索函数
        keyWord: {
            handler() {
                this.search()
            }

        }
    }
}
</script>